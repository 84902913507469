<template>
    <div
        class="
            flex
            flex-col
            justify-center
            bg-[rgba(255,255,255,0.8)]
            backdrop-blur-[6px]
        "
    >
        <div class="flex flex-col items-center text-center gap-3">
            <AppIconLock size="34"/>

            <div
                key="title"
                class="text-[16px] font-medium leading-[130%]"
            >
                {{ $t('available-in-pro') }}
            </div>

            <div
                v-if="$slots['hint']"
                key="hint"
                class="text-[14px] leading-[130%]"
            >
                <slot name="hint"/>
            </div>

            <AppLink
                key="action"
                wrapper
                class="mt-2"
                :to="{ name: 'p-pid-billing' }"
            >
                <AppButton small>
                    {{ $t('upgrade-to-pro') }}
                </AppButton>
            </AppLink>
        </div>
    </div>
</template>
