<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M9 16V10H5L12 3L19 10H15V16H9ZM5 20V18H19V20H5Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
