<script
    lang="ts"
    setup
>
    import type { AppInfoSectionItem } from '~/ts/types/app'

    type Props = {
        items: AppInfoSectionItem[]
        itemClass?: string
    }

    type Emit = {
        (event: 'try-to-toggle', value: string): void
    }

    const props = defineProps<Props>()
    const emit = defineEmits<Emit>()
</script>

<template>
    <div class="flex flex-1 flex-col w-full">
        <AppExpansionSection
            v-for="item in props.items"
            :key="item.name"
            :default-value="item.defaultValue ?? true"
            :prevent-value-change="item.disabled"
            :class="props.itemClass"
            @before-change="emit('try-to-toggle', item.name)"
            @toggled="item.onToggled?.($event)"
        >
            <template #title>
                <slot
                    :name="'item-header:' + item.name"
                    :item="item"
                >
                    {{ item.title }}
                </slot>
            </template>

            <div class="relative flex flex-col">
                <slot :name="'item-before-body:' + item.name"/>

                <slot
                    :name="'item-body:' + item.name"
                    :item="item"
                >
                    <template
                        v-for="value in item.values"
                        :key="value.key || value.label"
                    >
                        <slot
                            :name="`item-body:${ item.name }:value:${ value.key }`"
                            :value="value"
                        >
                            <div
                                :key="value.key || value.label"
                                class="mb-2 last:mb-0 text-[14px] font-[400] leading-[170%]"
                            >
                                <span class="mr-2.5 text-[#8a8f9e]">
                                    <component
                                        :is="value.iconComponent"
                                        v-if="value.iconComponent"
                                        key="icon"
                                        size="16"
                                        class="mr-2"
                                    />

                                    <span key="text">
                                        {{ value.label }}:
                                    </span>
                                </span>

                                <span class="text-black">
                                    <slot
                                        :name="`item-body:${ item.name }:value-text:${ value.key }`"
                                        :value="value"
                                    >
                                        {{ value.value }}
                                    </slot>
                                </span>
                            </div>
                        </slot>
                    </template>
                </slot>
            </div>
        </AppExpansionSection>
    </div>
</template>
