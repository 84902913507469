<script
    lang="ts"
    setup
>
    type Props = {
        disabled?: boolean
    }

    type Emit = {
        (event: 'activated'): void
        (event: 'deactivated'): void
        (event: 'dropped', value: File): void
    }

    const props = withDefaults(defineProps<Props>(), {
        disabled: false
    })

    const emit = defineEmits<Emit>()

    const active = ref<boolean>(false)

    const isUnsupportedType = ref<boolean>(false)

    const activate = (event: DragEvent): void => {
        const { items } = event.dataTransfer

        if (!fileUtil.isImage(items[0])) {
            return
        }

        if (!fileUtil.isSupportedImage(items[0])) {
            isUnsupportedType.value = true
        }

        active.value = true

        emit('activated')
    }

    const deactivate = (): void => {
        active.value = false
        isUnsupportedType.value = false

        emit('deactivated')
    }

    const deactivateDebounced = debounceFn(deactivate, 100)

    const setActiveValue = (value: boolean, event?: DragEvent) => {
        if (props.disabled) {
            if (active.value) {
                deactivate()
            }

            return
        }

        if (value && !active.value) {
            activate(event)
        }

        deactivateDebounced()
    }

    const onDrop = (event: DragEvent) => {
        if (props.disabled) {
            return
        }

        const { files } = event.dataTransfer

        setActiveValue(false)

        if (!fileUtil.isSupportedImage(files[0])) {
            return
        }

        if (!fileUtil.validateFileSize(files[0])) {
            useNotify().push({
                type: 'error',
                text: useLang().t('file-must-be-less-than-5mb')
            })

            return
        }

        emit('dropped', files[0])
    }
</script>

<template>
    <div
        :class="$style['drop-zone']"
        @dragenter.prevent="setActiveValue(true, $event)"
        @dragover.prevent="setActiveValue(true, $event)"
        @dragleave.prevent="setActiveValue(false, $event)"
        @dragend.prevent="setActiveValue(false, $event)"
    >
        <div
            v-if="active"
            key="drop-area"
            :class="$style['drop-zone__area']"
            @drop.prevent="onDrop"
        >
            <div :class="$style['drop-zone__area__container']">
                <div :class="$style['drop-zone__area__container__content']">
                    <div
                        :class="[
                            $style['drop-zone__area__container__content__icon'],
                            { [$style['drop-zone__area__container__content__icon--unsupported']]: isUnsupportedType }
                        ]"
                    >
                        <template v-if="isUnsupportedType">
                            <AppIconCancel size="34"/>
                        </template>

                        <slot
                            v-else
                            key="slot-icon"
                            name="icon"
                        >
                            <AppIconUpload size="34"/>
                        </slot>
                    </div>

                    <div :class="$style['drop-zone__area__container__content__text']">
                        <template v-if="isUnsupportedType">
                            {{ $t('unsupported-image-type') }}
                        </template>

                        <slot
                            v-else
                            key="slot-text"
                            name="text"
                        >
                            {{ $t('drag-image-here-to-send-it') }}
                        </slot>
                    </div>
                </div>
            </div>
        </div>

        <slot :active="active"/>
    </div>
</template>

<style
    lang="sass"
    module
    scoped
>
    .drop-zone
        position: relative

        &__area
            z-index: 100
            position: absolute
            display: flex
            top: 0
            left: 0
            width: 100%
            height: 100%
            padding: 8px
            background: #f6f7f8

            &__container
                pointer-events: none
                display: flex
                align-items: center
                justify-content: center
                flex: 1
                background: #ebeef1
                border: 1px dashed #aaadb8
                border-radius: 16px

                &__content
                    display: flex
                    flex-direction: column
                    align-items: center
                    width: 250px

                    &__icon
                        margin-bottom: 8px
                        animation: upload 500ms var(--transition-default-ease) 0s infinite alternate

                        &--unsupported
                            animation: cancel 500ms var(--transition-default-ease) 0s infinite alternate

                        @keyframes upload
                            0%
                                transform: translateY(0)
                            100%
                                transform: translateY(-8px)

                        @keyframes cancel
                            0%
                                transform: rotateY(0)
                            100%
                                transform: rotateY(45deg)

                    &__text
                        text-align: center
                        line-height: 19px
                        font-size: 16px
                        font-weight: 500
</style>
